.alert p {
    color: #155724 !important;
}

.footer p {
    color: #FFFFFF;
}

.footer h6 {
    color: #FFFFFF;
}

.footer label {
    color: #FFFFFF !important;
}

.mainLogo {
    height: 60px;
    margin: 1rem 0rem;
}

.phoneIcon {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    -webkit-mask-image: url(/icons/phone.svg);
    mask-image: url(/icons/phone.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px 0px 0px;
}

.emailIcon {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    -webkit-mask-image: url(/icons/email-icon.svg);
    mask-image: url(/icons/email-icon.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: relative;
    display: inline-block;
    margin: 0px 10px 0px 0px;
}

.russiatourismBox {
    border: 1px solid #FFFFFF;
    margin: 1rem 0rem;
    padding: .25rem;
}

.russiatourismBox img {
    max-height: 65px;
}

.bigfont {
    font-size: 13px;
}

.smfont {
    font-size: 12px;
}
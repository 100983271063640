@font-face {
    font-family: "HelveticaRegular";
    src: url("../font/HelveticaRegular.woff") format("woff");
}

body {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    background-color: #F9F9F9;
}


h1 {
    font-size: 28px !important;
}

h2 {
    font-size: 23px !important;
    ;
}

strong {
    font-weight: bold;
}

a {
    color: #1A2B50;
    text-decoration: none;
}

p {
    margin-bottom: .25rem;
}

li {
    /* list-style: none; */
}

label {
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.nav-link {
    color: #FFFFFF !important;
    text-transform: uppercase;
}

.mewtwo-flights--l {
    margin: 0 auto;
    max-width: 100% !important;
}


/* input number start*/
.children-age-block select {
    width: 100%;

}

.number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input {}

.number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    margin-top: 0px;
    height: 2px;
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
    text-align: center;
}

.number-input.number-input {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
}

.number-input.number-input button {
    /*width: 2rem;*/
    height: .7rem;
    padding: 0px 15px;
}

.number-input.number-input button:before,
.number-input.number-input button:after {
    width: .7rem;
    background-color: #495057;
}

.number-input.number-input input[type=number] {
    max-width: 3rem;
    padding: .1rem;
    border: 1px solid #ced4da;
    border-width: 0 1px;
    font-size: 1rem;
    height: 100%;
    color: #495057;
}

.img-card-ratio {
    width: 100%;
    height: auto;
    display: inline;
    vertical-align: top;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    -o-object-fit: cover;
}

body .flatpickr-calendar {
    display: none;
}


.night-svg {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    padding: 12px 0px;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/icons/calendar.svg);
}

.night-text {
    width: 100%;
    text-align: center;
    height: 100%;
    display: inline-block;
}

.form-group.required .control-label:after,
.form-group.required .form-label:after,
.required-info:after {
    content: "*";
    color: red;
    padding-left: 5px;
}

.calendar-table td {
    border: 0px !important;
}

td {
    border: 1px solid !important;
}

@media all and (max-width: 992px) {
    .daterangepicker {
        width: 278px !important;
        overflow-y: auto;
        max-height: 50vh;
    }
}
/* Menu styles */
.fx-menu {
    z-index: 5000;
}

/* Card styles */
.cardFlip {
    position: relative;
    perspective: 100%;
}

.front,
.back {
    backface-visibility: hidden;
    transition: transform 2.6s;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.front {
    display: block;
    position: relative;
    transform: rotateY(0deg);
}

.front::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/icons/flip.png');
    /* replace with your pattern image */
    background-size: 200px 200px;
    /* adjust the size of the pattern */
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
    /* adjust the opacity of the pattern */
    z-index: 1;
}

.back {
    transform: rotateY(180deg);
}

.cardFlip:hover .front {
    transform: rotateY(180deg);
}

.cardFlip:hover .back {
    transform: rotateY(0deg);
}

/* Header styles */
.gr-style {
    position: relative;
    text-align: center;
    display: inline;
    z-index: 1;
    font-weight: bold;
}

.gr-style::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -14px;
    width: 45px;
    height: 20px;
    background-color: rgb(75, 189, 75, .4);
    z-index: -1;
}
/**
 * Basic CSS Media Query Template
 * TODO: I should probably use Sass...
 * Author: Michael Vieth
 * ------------------------------------------
 *  Responsive Grid Media Queries - 1280, 1024, 768, 480
 *   1280-1024   - desktop (default grid)
 *   1024-768    - tablet landscape
 *   768-480     - tablet
 *   480-less    - phone landscape & smaller
 * --------------------------------------------
 */
@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {
    .breadcrumb-item a {
        font-size: 10px !important;
    }
}

@media all and (max-width: 768px) {
    .serv-icon-block {
        padding: 0px 1.5rem;
    }

    div:has(table) {
        width: 100% !important;
        overflow: visible;
    }

    .hotel-tabs .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"] {
        width: 100% !important;
        display: flex !important;
    }

    .rwt__tab .prev {
        position: absolute;
        left: 5px;
        top: calc(50% - 10px);
    }

    .rwt__tab .next {
        position: absolute;
        right: 5px;
        top: calc(50% - 10px);
    }

    .hotel-tabs .rwt__tab span {
        width: 100% !important;
        text-align: center !important;
        padding: 0px 10px;
    }

    .hotel-tabs .rwt__tablist button {
        display: none !important;
    }

    .mainform-tabs .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"] {
        width: 100% !important;
        display: flex !important;
    }

    .mainform-tabs .rwt__tab span {
        width: 100% !important;
        text-align: center !important;
        padding: 0px 10px;
    }

    .mainform-tabs .rwt__tablist button {
        display: none !important;
    }
}

/* Portrait */
@media screen and (orientation:portrait) {
    /* Portrait styles here */
}

/* Landscape */
@media screen and (orientation:landscape) {
    /* Landscape styles here */
}


/* CSS for iPhone, iPad, and Retina Displays */

/* Non-Retina */
@media screen and (-webkit-max-device-pixel-ratio: 1) {}

/* Retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation:portrait) {}

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation:landscape) {}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation:portrait) {}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation:landscape) {}